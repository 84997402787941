<template>
    <div class="bitsea_home_page">
        <div class="bitsea_home_page_01">
            <Menu/>
        </div>

        <img id="bitsea_home_page_bj" src="../../assets/images/bja.png" class="bitsea_bj_img">

        <div id="bitsea_home_page_02" class="bitsea_home_page_02">
            <div id="bitsea_home_page_02_divs" class="bitsea_home_page_02_divs">
                <div class="bitsea_home_page_021">
                    <div class="bitsea_home_page_0211">
                        <img src="../../assets/images/homepage_h1.png" class="bitsea_home_page_02111">
                        <img @click="getStarted()" src="../../assets/images/homepage_started_btn.png" class="bitsea_home_page_02112">
                    </div>

                    <div class="bitsea_home_page_0212">
                        <div class="bitsea_home_page_0212_diva" v-show="parseInt($store.state.networkIDstring) !== 223">
                            <img src="../../assets/images/homepage_bannercard.png" class="bitsea_home_page_02121"/>

                            <img v-show="parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502"
                                    src="https://bafybeicp7gbeeg7sjlaafi2sivbgrntlaotyai6znidv5f2kx5tdqzhj4a.ipfs.nftstorage.link/MonsterLandLogo.png" class="bitsea_home_page_02122"/>
                            <img v-show="parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212"
                                    src="https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png" class="bitsea_home_page_02122"/>

<!--                            <img v-if="isPC() ? false : !isAppleDevice()" src="https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif" class="bitsea_home_page_02122"/>-->
<!--                            <video autoplay controls src="https://bafybeieyrjb3ravdq3ztjxae4cvjuoxborhu3y4gd34vopnce54cc7s3ba.ipfs.nftstorage.link/seabox.mp4" class="bitsea_home_page_02122"/>-->

<!--                            <video v-show="isPC() ? true : isAppleDevice()" ref="myVideo" loop autoplay controls class="bitsea_home_page_02122">-->
<!--                                <source src="https://bafybeieyrjb3ravdq3ztjxae4cvjuoxborhu3y4gd34vopnce54cc7s3ba.ipfs.nftstorage.link/seabox.mp4" type="video/mp4">-->
<!--                            </video>-->

                            <div class="bitsea_home_page_021223">
                                <div class="bitsea_home_page_0212231">
                                    <div v-show="parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502" class="bitsea_home_page_02122311">MSTL #404</div>
                                    <div v-show="parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212" class="bitsea_home_page_02122311">HM #1</div>

                                    <div class="bitsea_home_page_02122312">
                                        <div v-show="parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502" class="bitsea_home_page_021223121">MonsterLand404</div>
                                        <div v-show="parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212" class="bitsea_home_page_021223121">Hiveswap Memorial NFT</div>

                                        <img src="../../assets/images/homepage_check_icon.png" class="bitsea_home_page_02123">
                                    </div>
                                </div>
                                <a @click="toLaunchpadClaim()" class="bitsea_home_page_02124_a">
                                    <img src="../../assets/images/homepage_arrow_icon.png" class="bitsea_home_page_02124"/>
                                    Mint
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <img class="bitsea_home_page_022" src="../../assets/images/Line6.png">

                <div class="bitsea_home_page_statistics">
                    <div class="statistics_div">
                        <div class="statistics_div_01">Users : </div>
                        <div class="statistics_div_02">&nbsp; {{ users }}</div>
                    </div>
                    <div class="statistics_div">
                        <div class="statistics_div_01">Mintage : </div>
                        <div class="statistics_div_02">&nbsp; {{ mintage }}</div>
                    </div>
                    <div class="statistics_div">
                        <div class="statistics_div_01">Transactions : </div>
                        <div class="statistics_div_02">&nbsp; {{ transactions }}</div>
                    </div>
<!--                    <div class="statistics_div">-->
<!--                        <div class="statistics_div_01">Volume : </div>-->
<!--                        <div class="statistics_div_02">&nbsp; {{ BigNumberDiv(volume, 1e18) }}</div>-->
<!--                    </div>-->
                </div>

                <img class="bitsea_home_page_022" src="../../assets/images/Line6.png">

                <div class="bitsea_home_page_023">
                    <div class="bitsea_home_page_0231">
                        <div class="bitsea_home_page_02311">NFTs</div>
                        <div class="bitsea_home_page_0231_all">
                            <div class="bitsea_home_page_02312">Seabox</div>
                            <div class="bitsea_home_page_02312">MonsterLand</div>
                            <div class="bitsea_home_page_02312">GenesisBox</div>
                            <div class="bitsea_home_page_02312">Hiveswap Memorial NFT</div>
                            <div class="bitsea_home_page_02312">...</div>
                        </div>
                        <img class="bitsea_home_page_02314" src="../../assets/images/homepage_cir_icon.png">
                    </div>
                    <img class="bitsea_home_page_0234" src="../../assets/images/hpmepage_line.png">
                    <div class="bitsea_home_page_0232">
                        <img class="bitsea_home_page_02321" src="../../assets/images/homepage_pol_icon.png">
                        <img class="bitsea_home_page_02322" src="../../assets/images/homepage_tex.png">
                        <img class="bitsea_home_page_02323" src="../../assets/images/homepage_pol_icon.png">
                        <div class="bitsea_home_page_02324">
                            <div class="bitsea_home_page_023241">Chains</div>
                            <div class="bitsea_home_page_023242">BEVM / MAP Protocol / B² Network</div>
                        </div>
                    </div>
                    <img class="bitsea_home_page_0235" src="../../assets/images/hpmepage_line.png">
                    <div class="bitsea_home_page_0233">
                        <img class="bitsea_home_page_02331" src="../../assets/images/homepage_cir_icon.png">
                        <div class="bitsea_home_page_02332">Protocols</div>
                        <div class="bitsea_home_page_02333">ERC 721</div>
                        <div class="bitsea_home_page_02333_1">ERC 1155</div>
                        <div class="bitsea_home_page_02333_2">ERC 404</div>
                        <div class="bitsea_home_page_02334">...</div>
                    </div>
                </div>
            </div>

            <div class="bitsea_home_page_03">
                <Bottom/>
            </div>
        </div>
    </div>
</template>

<script>
    import Menu from "../../components/Menu";
    import Bottom from "../../components/Bottom";
    import toolUtils from "../../tools/toolUtils";
    import { mixin1 } from "../../tools/mixins";
    import * as metaMaskTools from "../../tools/chain";

    import transfer from "../../tools/transfer";
    import {BigNumberDiv} from "../../tools";

    export default {
        name: "Index",
        components: { Menu, Bottom },
        mixins: [ mixin1 ],
        data() {
            return {
                bitseaPageWidth: null,
                bitseaPageHeight: 0,
                users: 0,
                mintage: 0,
                transactions: 0,
                volume: 0,
            }
        },
        watch: {},
        created() {
        },
        mounted() {
            // const video = this.$refs.myVideo;
            // video.addEventListener('ended', () => {
            //     video.currentTime = 0;
            //     video.play();
            // });
            // video.play();

            this.getStatisticsData();

            setTimeout(() => {
                this.initPageData();
            }, 500);

            window.onresize = () => {
                return (() => {
                    this.setBitseaPageHeight();
                })();
            }

            setTimeout(() => {
                this.setBitseaPageHeight();
            }, 10);
            setTimeout(() => {
                this.setBitseaPageHeight();
            }, 500);
        },
        destroyed() {
        },
        methods: {
            async initPageData() {
                await metaMaskTools.initParams().then(() => {});
            },
            setBitseaPageHeight() {
                let divWidth = document.getElementById("bitsea_home_page_02").clientWidth;
                let divHeight = document.getElementById("bitsea_home_page_02").clientHeight;
                let minDivHeight = divHeight * 0.03;
                let divHeights = document.getElementById("bitsea_home_page_02_divs").clientHeight;

                if (divHeights + 170 + minDivHeight > divHeight) {
                    document.getElementById("bitsea_bottom_page").style.position = "relative";
                } else {
                    document.getElementById("bitsea_bottom_page").style.position = "absolute";
                }

                let bjActualWidth = ((divHeight / 1158) * 1920);
                if (divWidth < bjActualWidth) {
                    $('#bitsea_home_page_bj').animate({ width : bjActualWidth }, 0);
                } else {
                    $('#bitsea_home_page_bj').animate({ width : divWidth }, 0);
                }
            },
            isPC() {
                return toolUtils.isPC();
            },
            isAppleDevice() {
                return toolUtils.isAppleDevice();
            },
            getStarted() {
                // this.$message.warning('Coming soon!');
                // return;

                transfer.$emit("toMenuPage", 2);
            },
            toLaunchpadClaim() {
                transfer.$emit("toMenuClaim");
            },
            BigNumberDiv(account, num, col) {
                return BigNumberDiv(account, num, col);
            },
            async getStatisticsData() {
                try {
                    this.users = 0;
                    this.mintage = 0;
                    this.transactions = 0;
                    this.volume = 0;

                    await this.$http.getStatistics(11501).then(async (res) => {
                        console.log(res);
                        if (res) {
                            let data0 = res.data0 ? res.data0 : 0;
                            let data1 = res.data1 ? res.data1 : 0;
                            let data2 = res.data2 ? res.data2 : 0;
                            let data3 = res.data3 ? res.data3 : 0;

                            this.users = parseInt(this.users) + parseInt(data0);
                            this.mintage = parseInt(this.mintage) + parseInt(data1);
                            this.transactions = parseInt(this.transactions) + parseInt(data2);
                            this.volume = parseInt(this.volume) + parseInt(data3);
                        }
                    })

                    await this.$http.getStatistics(22776).then(async (res) => {
                        console.log(res);
                        if (res) {
                            let data0 = res.data0 ? res.data0 : 0;
                            let data1 = res.data1 ? res.data1 : 0;
                            let data2 = res.data2 ? res.data2 : 0;
                            let data3 = res.data3 ? res.data3 : 0;

                            this.users = parseInt(this.users) + parseInt(data0);
                            this.mintage = parseInt(this.mintage) + parseInt(data1);
                            this.transactions = parseInt(this.transactions) + parseInt(data2);
                            this.volume = parseInt(this.volume) + parseInt(data3);
                        }
                    })

                    // await this.$http.getStatistics(223).then(async (res) => {
                    //     console.log(res);
                    //     if (res) {
                    //         let data0 = res.data0 ? res.data0 : 0;
                    //         let data1 = res.data1 ? res.data1 : 0;
                    //         let data2 = res.data2 ? res.data2 : 0;
                    //         let data3 = res.data3 ? res.data3 : 0;
                    //
                    //         this.users = parseInt(this.users) + parseInt(data0);
                    //         this.mintage = parseInt(this.mintage) + parseInt(data1);
                    //         this.transactions = parseInt(this.transactions) + parseInt(data2);
                    //         this.volume = parseInt(this.volume) + parseInt(data3);
                    //     }
                    // })
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }
</script>

<style lang="less">
    .bitsea_home_page {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        .bitsea_bj_img {
            position: absolute;
            width: 100%;
            height: calc(100% - 84px);
            top: 84px;
            bottom: 0;
            left: 0;
        }
        .bitsea_home_page_01 {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        .bitsea_home_page_02::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        .bitsea_home_page_02 {
            overflow-y: auto;
            position: absolute;
            width: 100%;
            top: 84px;
            bottom: 0;
            .bitsea_home_page_02_divs {
                position: relative;
                width: 80%;
                top: 0;
                bottom: 0;
                left: 10%;
                .bitsea_home_page_021 {
                    width: 100%;
                    margin-top: 3%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;

                    @media (max-width: 1023px){
                        margin-top: 5%;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-items: stretch;
                    }
                    .bitsea_home_page_0211 {
                        width: 60%;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        align-content: flex-start;
                        align-items: flex-start;

                        @media (max-width: 1023px){
                            width: 80%;
                        }
                        .bitsea_home_page_02111 {
                            width: 90%;
                        }
                        .bitsea_home_page_02112 {
                            width: 29%;
                            margin-left: -1%;
                            margin-top: 2%;
                            cursor: pointer;
                            opacity: 0.8;
                            &:hover{
                                opacity: 1;
                            }

                            @media (max-width: 1023px){
                                width: 50%;
                            }
                        }
                    }
                    .bitsea_home_page_0212 {
                        width: 40%;
                        position: relative;

                        @media (max-width: 1023px){
                            width: 60%;
                        }
                        .bitsea_home_page_0212_diva {
                            width: 80%;
                            margin-left: 20%;
                            border: 1px solid #7a7a7a;
                            border-radius: 10px;

                            @media (max-width: 1023px){
                                margin-left: 0;
                            }
                            .bitsea_home_page_02121 {
                                width: 100%;
                                margin-left: 0;
                                -webkit-filter: blur(38px);
                                filter: blur(38px);
                            }
                            .bitsea_home_page_02122 {
                                position: absolute;
                                width: 70%;
                                top: 5%;
                                left: 25%;

                                @media (max-width: 1023px){
                                    top: 2%;
                                    left: 5%;
                                }
                            }
                            .bitsea_home_page_021223 {
                                margin-top: 1%;
                                position: absolute;
                                width: 68%;
                                bottom: 4%;
                                left: 26%;
                                color: white;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                align-items: center;

                                @media (max-width: 1023px){
                                    left: 6%;
                                    bottom: 3%;
                                }
                                .bitsea_home_page_0212231 {
                                    .bitsea_home_page_02122311 {
                                        font-size: 18px;
                                        font-weight: 600;
                                    }
                                    .bitsea_home_page_02122312 {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: flex-start;
                                        align-items: center;
                                        .bitsea_home_page_021223121 {
                                            font-size: 16px;
                                            margin-top: 5px;

                                            @media (max-width: 1023px){
                                                width: 120px;
                                            }
                                        }
                                        .bitsea_home_page_02123 {
                                            margin-left: 3%;
                                            width: 18px;
                                            margin-top: 5px;
                                        }
                                    }
                                }
                                .bitsea_home_page_02124_a {
                                    display: flex;
                                    display: flex;
                                    flex-direction: column;
                                    flex-wrap: nowrap;
                                    justify-content: flex-start;
                                    align-items: center;
                                    cursor: pointer;
                                    font-size: 14px;
                                }
                                .bitsea_home_page_02124 {
                                    width: 30px;
                                    cursor: pointer;
                                    opacity: 0.8;
                                }
                                .bitsea_home_page_02124:hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
                .bitsea_home_page_022 {
                    width: 100%;
                    margin: 5% 0;
                }
                .bitsea_home_page_statistics {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-around;
                    align-items: center;

                    @media (max-width: 1023px){
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: space-around;
                        align-items: flex-start;
                    }
                    .statistics_div {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: center;
                        align-items: center;
                        .statistics_div_01 {
                            font-size: 20px;
                            color: white;
                        }
                        .statistics_div_02 {
                            font-size: 26px;
                            font-weight: bold;
                            color: #f4933c;
                        }
                    }
                }
                .bitsea_home_page_023 {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 70px;
                    .bitsea_home_page_0231 {
                        position: relative;
                        min-height: 265px;
                        width: 30%;
                        border: 1px solid #666666;
                        border-radius: 10px;
                        .bitsea_home_page_02311 {
                            text-align: right;
                            font-size: 26px;
                            // font-family: ArialRoundedMT;
                            font-weight: bold;
                            color: white;
                            margin: 8%;
                        }
                        .bitsea_home_page_0231_all {
                            width: 100%;
                            position: absolute;
                            bottom: 6%;
                            .bitsea_home_page_02312 {
                                text-align: right;
                                font-size: 18px;
                                // font-family: ArialRoundedMT;
                                margin: 2% 8%;
                                color: #b3b3b3;
                            }
                            .bitsea_home_page_02313 {
                                text-align: right;
                            }
                        }
                        .bitsea_home_page_02314 {
                            position: absolute;
                            right: -20px;
                            bottom: 2%;
                        }
                    }
                    .bitsea_home_page_0232 {
                        position: relative;
                        width: 32%;
                        min-height: 265px;
                        border: 1px solid #666666;
                        border-radius: 10px;
                        .bitsea_home_page_02321 {
                            position: absolute;
                            left: -20px;
                            top: 2%;
                            transform: rotate(180deg);
                        }
                        .bitsea_home_page_02322 {
                            width: 68%;
                            margin-left: 8%;
                            margin-top: 2%;
                        }
                        .bitsea_home_page_02323 {
                            position: absolute;
                            right: -20px;
                            bottom: 2%;
                        }
                        .bitsea_home_page_02324 {
                            position: absolute;
                            bottom: 15%;
                            width: 100%;
                            .bitsea_home_page_023241 {
                                font-size: 30px;
                                // font-family: ArialRoundedMT;
                                font-weight: bold;
                                color: white;
                                text-align: center;
                            }
                            .bitsea_home_page_023242 {
                                font-size: 18px;
                                // font-family: ArialRoundedMT;
                                color: #b3b3b3;
                                text-align: center;
                            }
                        }
                    }
                    .bitsea_home_page_0233 {
                        position: relative;
                        width: 30%;
                        min-height: 265px;
                        border: 1px solid #666666;
                        border-radius: 10px;
                        .bitsea_home_page_02331 {
                            position: absolute;
                            left: -20px;
                        }
                        .bitsea_home_page_02332 {
                            font-size: 26px;
                            // font-family: ArialRoundedMT;
                            font-weight: bold;
                            color: white;
                            text-align: left;
                            margin: 8%;
                        }
                        .bitsea_home_page_02333 {
                            position: absolute;
                            width: 105px;
                            margin-left: 8%;
                            bottom: 38%;
                            font-size: 18px;
                            // font-family: ArialRoundedMT;
                            color: #b3b3b3;
                            text-align: left;
                        }
                        .bitsea_home_page_02333_1 {
                            position: absolute;
                            width: 105px;
                            margin-left: 8%;
                            bottom: 27%;
                            font-size: 18px;
                            color: #b3b3b3;
                            text-align: left;
                        }
                        .bitsea_home_page_02333_2 {
                            position: absolute;
                            width: 105px;
                            margin-left: 8%;
                            bottom: 16%;
                            font-size: 18px;
                            color: #b3b3b3;
                            text-align: left;
                        }
                        .bitsea_home_page_02334 {
                            position: absolute;
                            width: 105px;
                            margin-left: 8%;
                            bottom: 9%;
                            font-size: 18px;
                            // font-family: ArialRoundedMT;
                            color: #b3b3b3;
                            text-align: left;
                        }
                    }
                    .bitsea_home_page_0234 {
                        margin-top: 1.5%;
                    }
                    .bitsea_home_page_0235 {
                        margin-top: 1.5%;
                    }
                }
            }
            .bitsea_home_page_03 {
                width: 100%;
            }
        }
    }
</style>
